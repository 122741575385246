
import { useRoute, useRouter } from "vue-router";
import { defineComponent, onMounted, ref } from "vue";
import { PREFECTURE_LIST } from "@/commons/constants";
import { Field, Form } from "vee-validate";
import * as Yup from "yup";
import { sidClient } from "@/plugins/auth";
import { Core as YubinBangoCore } from "yubinbango-core2";
import { LinkageItem } from "@/modules/linkage/linkageInfo";
import { getServiceName, isSiteRegistered } from "@/commons/commonUtility";
import { injectSiteUniqItem, injectUserBaseItem } from "@/commons/linkage/linkageCommon";
import { UserShueishaOnline } from "@/commons/interfaces";
import {
    checkShueishaOnlineIsSendMailMagazineReqd,
} from "@/commons/inputCheckSiteUniqueInfo";

export default defineComponent({
    name: "ShueishaOnlineProfile",
    components: {
        Form,
        Field
    },
    props: {
        // 次画面の遷移先
        nextRoute: { type: String, required: true },
    },
    setup(props) {
        const route = useRoute();
        const router = useRouter();
        const { linkageInfo, routeNames, serviceTermUrl } = LinkageItem.inject();

        const userBaseItem = injectUserBaseItem(linkageInfo.linkageId);
        const siteUniqItem = injectSiteUniqItem(linkageInfo.linkageId);
        const prefectureList = PREFECTURE_LIST;

        const userBase = userBaseItem.userData;
        const userShueishaOnline = siteUniqItem.userData as UserShueishaOnline;

        const formRef = ref<any>();
        // 初回サービス登録判定
        const firstServiceReg = route.name === `${routeNames.LOGIN_PROFILE}Child` && !isSiteRegistered(linkageInfo.linkageId, userBase);

        const serviceName = getServiceName(linkageInfo.linkageId);

        const moveConfirm = async () => {
            userBaseItem.storeStorage();
            siteUniqItem.storeStorage();
            await router.push({ name: props.nextRoute });
        };

        const schema = Yup.object().shape({
            isSendMailMagazine: checkShueishaOnlineIsSendMailMagazineReqd
        });

        onMounted(async () => {

            // 基本属性情報の有無を判断
            if (userBase.identityId) {

                if (userShueishaOnline.identityId) {
                    formRef.value.setFieldValue("isSendMailMagazine", userShueishaOnline.isSendMailMagazine);
                }

                const ret = await formRef.value.validate();
                // vee-validate 4.6.7 以降、slotのerrorsと連動していないため、setする
                formRef.value.setErrors(ret.errors);
            } else {
                // メールアドレスをセット
                try {
                    const userData = await sidClient.getUserData();
                    const email = userData.UserAttributes.filter((obj) => obj.Name === "email")[0].Value;
                    userBase.baseInfo.email = email;
                } catch (e) {
                    await router.push({
                        name: routeNames.SIGNUP_IDENTIFICATION,
                        query: { retUrl: router.currentRoute.value.name as string },
                        params: { message: "認証の有効期限が切れました。再ログイン後にもう一度最初から操作をお願いします。" },
                    });
                }
            }
        });

        return {
            userBase,
            userShueishaOnline,
            prefectureList,
            schema,
            moveConfirm,
            firstServiceReg,
            formRef,
            serviceName,
            serviceTermUrl,
        };
    },
});
