import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ha-mypage l-container" }
const _hoisted_2 = { class: "ha__ttl c-ttl" }
const _hoisted_3 = { class: "ha__sec ha-mypage__sec" }
const _hoisted_4 = { class: "p-mypage__field-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HapiacaMailMagazineConfirm = _resolveComponent("HapiacaMailMagazineConfirm")!
  const _component_ShueishaOnlineMailMagazineConfirm = _resolveComponent("ShueishaOnlineMailMagazineConfirm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.title) + " 変更確認", 1),
    _createElementVNode("section", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (_ctx.linkageInfo.linkageId === _ctx.HAPIACA_LINKAGE_ID)
          ? (_openBlock(), _createBlock(_component_HapiacaMailMagazineConfirm, {
              key: 0,
              prevRoute: _ctx.routeNames.MAIL_MAGAZINE,
              nextRoute: _ctx.routeNames.MAIL_MAGAZINE_COMPLETE
            }, null, 8, ["prevRoute", "nextRoute"]))
          : (_ctx.linkageInfo.linkageId === _ctx.SHUEISHA_ONLINE_LINKAGE_ID)
            ? (_openBlock(), _createBlock(_component_ShueishaOnlineMailMagazineConfirm, {
                key: 1,
                prevRoute: _ctx.routeNames.MAIL_MAGAZINE,
                nextRoute: _ctx.routeNames.MAIL_MAGAZINE_COMPLETE
              }, null, 8, ["prevRoute", "nextRoute"]))
            : _createCommentVNode("", true)
      ])
    ])
  ]))
}