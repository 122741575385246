
import { useRouter } from "vue-router";
import { defineComponent } from "vue";
import { Field, Form } from "vee-validate";
import { checkMailMagazineIsReqd } from "@/commons/inputCheckSiteUniqueInfo";
import * as Yup from "yup";
import { LinkageItem } from "@/modules/linkage/linkageInfo";
import { getServiceName } from "@/commons/commonUtility";
import { injectSiteUniqItem } from "@/commons/linkage/linkageCommon";
import { UserHpplusAcademia } from "@/commons/interfaces";
import useDeterring from "@/commons/useDeterring";
import { useSidClient } from "@/plugins/auth";

export default defineComponent({
    name: "HapiacaMailMagazine",
    components: {
        Form,
        Field
    },
    setup(props) {
        const sidClient = useSidClient();
        const router = useRouter();
        const { isDeterringRef, asyncDeterringWrap } = useDeterring();
        const { linkageInfo, fetchToken, routeNames } = LinkageItem.inject();

        const siteUniqItem = injectSiteUniqItem(linkageInfo.linkageId);
        const userHapiaca: UserHpplusAcademia = siteUniqItem.userData;
        const serviceName = getServiceName(linkageInfo.linkageId);

        const moveConfirm = async () => {
            siteUniqItem.storeStorage();
            await router.push({ name: routeNames.MAIL_MAGAZINE_CONFIRM });
        };

        const schema = Yup.object().shape({
            mainMailMagazine: checkMailMagazineIsReqd,
        });

        const backLinkageSite = async () => {
            // コールバック先で振り分けが行われるため、トークン発行
            await asyncDeterringWrap(fetchToken, this, sidClient).catch(error => {
                // エラー画面遷移
                router.push({ name: routeNames.GENERAL_ERROR, params:{ message: "認証処理に失敗しました。" } });
            });

            // 各サービスサイトのマイページTOPへ戻る（コールバック）
            window.location.href = linkageInfo.callbackUrlWithToken as string;
        };

        return {
            userHapiaca,
            schema,
            moveConfirm,
            serviceName,
            isDeterringRef,
            backLinkageSite,
        };
    },
});
