
import { defineComponent } from "vue";
import { LinkageItem } from "@/modules/linkage/linkageInfo";
import HapiacaMailMagazineConfirm from "@/components/Linkage/Sites/HapiacaMailMagazineConfirm.vue";
import ShueishaOnlineMailMagazineConfirm from "@/components/Linkage/Sites/ShueishaOnlineMailMagazineConfirm.vue";
import { HAPIACA_LINKAGE_ID } from "@/commons/linkage/sites/hapiaca";
import { SHUEISHA_ONLINE_LINKAGE_ID } from "@/commons/linkage/sites/shueisha_online";

export default defineComponent({
    name: "LinkageMailMagazineConfirm",
    components: {
        HapiacaMailMagazineConfirm,
        ShueishaOnlineMailMagazineConfirm,
    },
    setup() {
        const { linkageInfo, routeNames } = LinkageItem.inject();
        const title = linkageInfo.linkageId === SHUEISHA_ONLINE_LINKAGE_ID ? "ニュースレター配信設定" : "メールマガジン購読";
        return {
            linkageInfo,
            routeNames,
            title,
            HAPIACA_LINKAGE_ID,
            SHUEISHA_ONLINE_LINKAGE_ID,
        };
    }
});
