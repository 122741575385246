
import { defineComponent } from "vue";

export default defineComponent({
    name: "LinkageMailMagazineRescueProfileConfirm",
    setup() {
        return {
        };
    }
});
