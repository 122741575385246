
import { defineComponent, PropType, ref } from "vue";
import { LinkageItem } from "@/modules/linkage/linkageInfo";
import LinkageHeader from "@/components/Linkage/LinkageHeader.vue";
import LinkageFooter from "@/components/Linkage/LinkageFooter.vue";
import { useRoute, useRouter } from "vue-router";
import { LinkageUserItem } from "@/modules/linkage/linkageUserItem";
import { getLinkageSiteUniqType, getLinkageUserBaseType } from "@/commons/linkage/linkageCommon";
import * as Yup from "yup";

export default defineComponent({
    name: "LinkageParent",
    components: {
        LinkageHeader,
        LinkageFooter,
    },
    props: {
        linkageId: { type: Number, required: true },
        linkageName: { type: String, required: true },
        pathName: { type: String, required: true },
        siteUniqApiPath: { type: String, required: true },
        siteDefaultCallbackUrl: { type: String, required: true },
        acceptCallbackUrls: { type: Array as PropType<string[]>, required: true },
        serviceTermUrl: { type: String, required: true },
        emailDomains: { type: Array as PropType<string[]>, required: true },
        linkageSubId: { type: Number, required: false },
        loginStateUnused: { type: Boolean, required: false },
        emailParamNotRequired: { type: Boolean, required: false },
    },
    setup(props) {
        const router = useRouter();
        const route = useRoute();
        const codeChallenge = (route.query.code_challenge) ? route.query.code_challenge as string : route.query.codeChallenge as string;
        const callback = route.query.callback as string;
        const email = route.query.email as string | null;
        const fromMailLink = route.query.from_mail_link as string | null;

        const waitViewRef = ref(false);

        // 連携情報をprovide
        const linkageItem = LinkageItem.create(props.linkageId, props.linkageName, props.pathName, props.siteUniqApiPath, props.siteDefaultCallbackUrl, props.serviceTermUrl, props.emailDomains, props.linkageSubId);
        const routeNames = linkageItem.routeNames;

        if (props.loginStateUnused || callback || email) {
            linkageItem.setLinkageInfo({
                linkageId: props.linkageId,
                linkageName: props.linkageName,
                pathName: props.pathName,
                siteUniqApiPath: props.siteUniqApiPath,
                codeChallenge: codeChallenge,
                callbackUrl: callback,
                email: email,
                callbackUrlWithToken: null,
                linkageSubId: props.linkageSubId ?? null,
                loginStateUnused: props.loginStateUnused ?? fromMailLink ?? false,
                emailParamNotRequired: props.emailParamNotRequired ?? false,
            });
            linkageItem.storeStorage();
        }
        linkageItem.provide();

        // 必須情報のチェック
        let hasRequiredParams = true;
        if (!props.loginStateUnused && (!linkageItem.linkageInfo.callbackUrl || !linkageItem.linkageInfo.codeChallenge)) {
            hasRequiredParams = false;
        }
        // クエリパラメータチェック
        let isValidEmail = true;
        if (email) {
            isValidEmail = Yup.string().default("").min(1).email().isValidSync(linkageItem.linkageInfo.email);
        }
        // コールバックチェック
        let isValidCallback = true;
        if (callback) {
            isValidCallback = Boolean(props.acceptCallbackUrls.find(urlRegex => callback.match(RegExp(urlRegex,"g"))));
        }

        // 基本属性情報をprovide
        const userBaseItem = LinkageUserItem.create(getLinkageUserBaseType(props.linkageId));
        userBaseItem.provide();
        // サイト固有情報をprovide
        const siteUniqItem = LinkageUserItem.create(getLinkageSiteUniqType(props.linkageId));
        siteUniqItem.provide();

        // 必須情報の形式不正・情報不足の場合（クエリにもセッションストレージにも無い場合）はエラー画面へ
        if (!hasRequiredParams || !isValidEmail || !isValidCallback) {
            router.push({ name: routeNames.GENERAL_ERROR, params: { message: "URLに不備があります" } }).finally(() => waitViewRef.value = true);
        } else {
            waitViewRef.value = true;
        }

        return {
            waitViewRef
        };
    }
});
