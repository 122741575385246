
import { useRoute, useRouter } from "vue-router";
import { defineComponent } from "vue";
import { useSidClient } from "@/plugins/auth";
import { UserShueishaOnline } from "@/commons/interfaces";
import useDeterring from "@/commons/useDeterring";
import { LinkageItem } from "@/modules/linkage/linkageInfo";
import { getServiceName, isAuthFailed } from "@/commons/commonUtility";
import { injectSiteUniqItem } from "@/commons/linkage/linkageCommon";

export default defineComponent({
    name: "ShueishaOnlineMailMagazineConfirm",
    props: {
        // キャンセルボタン押下時の遷移先
        prevRoute: { type: String, required: true },
        // 送信するボタン押下時の遷移先(nullの場合はトークンコールバックする)
        nextRoute: { type: String },
    },
    setup(props) {
        const sidClient = useSidClient();
        const router = useRouter();
        const route = useRoute();
        const { isDeterringRef, setNotRelease, asyncDeterringWrap } = useDeterring();
        const { linkageInfo, fetchToken, routeNames, siteUniqApiPath } = LinkageItem.inject();

        const siteUniqItem = injectSiteUniqItem(linkageInfo.linkageId);

        const userShueishaOnline = siteUniqItem.userData as UserShueishaOnline;
        const serviceName = getServiceName(linkageInfo.linkageId);

        const update = async () => {

            try {
                // サイト固有情報更新
                const reqShueishaOnlineInfo = Object.assign({}, userShueishaOnline);
                await sidClient.requestApi({
                    method: "PUT",
                    path: siteUniqApiPath,
                    body: reqShueishaOnlineInfo
                });
                siteUniqItem.removeStorage();
            } catch (e) {
                if (isAuthFailed(e)) {
                    if (linkageInfo.email) {
                        if (route.name === routeNames.SIGNUP_CONFIRM) {
                            // 本人確認画面へ遷移
                            await router.push({ name: routeNames.SIGNUP_IDENTIFICATION, params: { message: "認証の有効期限が切れました。再ログイン後にもう一度最初から操作をお願いします。" } });
                        } else {
                            // 本人確認画面へ遷移
                            await router.push({ name: routeNames.IDENTIFICATION, query: { retUrl: routeNames.PROFILE }, params: { message: "認証の有効期限が切れました。再ログイン後にもう一度最初から操作をお願いします。" } });
                        }
                    } else {
                        // ログイン画面へ遷移
                        await router.push({ name: routeNames.LOGIN, params: { message: "認証の有効期限が切れました。再ログイン後にもう一度最初から操作をお願いします。" } });
                    }
                    return;
                }
                // 前画面に戻る
                await siteUniqItem.fetchUserData(sidClient)
                    .finally(async () => {
                        siteUniqItem.userData.userName = (await sidClient.getUserData()).Username;
                        router.push({ name: props.prevRoute, params: { message: "会員情報の更新に失敗しました。再度お試し下さい。" } });
                    });
                return;
            }

            if (props.nextRoute) {
                // 完了画面へ遷移
                await router.push({ name: props.nextRoute });
            } else {
                try {
                    await fetchToken(sidClient);
                    setNotRelease();
                    window.location.href = linkageInfo.callbackUrlWithToken as string;
                } catch (e) {
                    await router.push({ name: routeNames.GENERAL_ERROR, params: { message: "お手数をおかけしますが、しばらく時間を置いてから、再度操作していただけますようお願いいたします。" } });
                }
            }
        };

        return {
            serviceName,
            isDeterringRef,
            asyncDeterringWrap,
            userShueishaOnline,
            update,
            linkageInfo,
            routeNames,
            router
        };
    },
});
