
import { defineComponent, ref } from "vue";
import { LinkageItem } from "@/modules/linkage/linkageInfo";
import { injectSiteUniqItem, injectUserBaseItem } from "@/commons/linkage/linkageCommon";
import HapiacaMailMagazine from "@/components/Linkage/Sites/HapiacaMailMagazine.vue";
import ShueishaOnlineMailMagazine from "@/components/Linkage/Sites/ShueishaOnlineMailMagazine.vue";
import { HAPIACA_LINKAGE_ID } from "@/commons/linkage/sites/hapiaca";
import { SHUEISHA_ONLINE_LINKAGE_ID } from "@/commons/linkage/sites/shueisha_online";

export default defineComponent({
    name: "LinkageMailMagazine",
    components: {
        HapiacaMailMagazine,
        ShueishaOnlineMailMagazine,
    },
    props: {
        message: { type: String },
    },
    setup(props) {
        const { linkageInfo, routeNames } = LinkageItem.inject();
        const userBaseItem = injectUserBaseItem(linkageInfo.linkageId);
        const siteUniqItem = injectSiteUniqItem(linkageInfo.linkageId);

        const errorMsgRef = ref(props.message);
        const infoMsgRef = ref("");

        // サイト毎の必須情報チェック
        if (!userBaseItem.checkRequired() || !siteUniqItem.checkRequired()) {
            infoMsgRef.value = "ご利用にあたり登録情報の確認をお願いいたします。";
        }

        const title = linkageInfo.linkageId === SHUEISHA_ONLINE_LINKAGE_ID ? "ニュースレター配信設定" : "メールマガジン購読";

        return {
            linkageInfo,
            routeNames,
            errorMsgRef,
            infoMsgRef,
            title,
            HAPIACA_LINKAGE_ID,
            SHUEISHA_ONLINE_LINKAGE_ID,
        };
    }
});
