
import { defineComponent } from "vue";
import { LinkageItem } from "@/modules/linkage/linkageInfo";
import { useSidClient } from "@/plugins/auth";
import useDeterring from "@/commons/useDeterring";
import { useRouter } from "vue-router";
import { HAPIACA_LINKAGE_ID } from "@/commons/linkage/sites/hapiaca";
import { SHUEISHA_ONLINE_LINKAGE_ID } from "@/commons/linkage/sites/shueisha_online";

export default defineComponent({
    name: "LinkageMailMagazineComplete",
    components: {
    },
    setup() {
        const sidClient = useSidClient();
        const router = useRouter();
        const { linkageInfo, fetchToken, routeNames } = LinkageItem.inject();
        const { isDeterringRef, asyncDeterringWrap } = useDeterring();

        const title = linkageInfo.linkageId === SHUEISHA_ONLINE_LINKAGE_ID ? "ニュースレター配信設定" : "メールマガジン購読";

        const callbackToken = () => {
            window.location.href = linkageInfo.callbackUrlWithToken as string;
        };

        asyncDeterringWrap(fetchToken, this, sidClient)
            .then(() => {
                // 画面遷移
                callbackToken();
            })
            .catch(error => {
                // エラー画面遷移
                router.push({ name: routeNames.GENERAL_ERROR, params: { message: "認証処理に失敗しました。" } });
            });

        return {
            isDeterringRef,
            callbackToken,
            linkageInfo,
            title,
            HAPIACA_LINKAGE_ID,
            SHUEISHA_ONLINE_LINKAGE_ID,
        };
    }
});
