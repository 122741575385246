import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ha-mypage l-container" }
const _hoisted_2 = { class: "ha__ttl c-ttl" }
const _hoisted_3 = { class: "ha__sec ha-mypage__sec" }
const _hoisted_4 = { class: "p-mypage__field-container" }
const _hoisted_5 = { class: "p-messages" }
const _hoisted_6 = { class: "p-errors" }
const _hoisted_7 = { class: "c-error" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HapiacaMailMagazine = _resolveComponent("HapiacaMailMagazine")!
  const _component_ShueishaOnlineMailMagazine = _resolveComponent("ShueishaOnlineMailMagazine")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createElementVNode("section", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createElementVNode("div", _hoisted_5, [
          _createElementVNode("p", null, _toDisplayString(_ctx.infoMsgRef), 1)
        ], 512), [
          [_vShow, _ctx.infoMsgRef]
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_6, [
          _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.errorMsgRef), 1)
        ], 512), [
          [_vShow, _ctx.errorMsgRef]
        ]),
        (_ctx.linkageInfo.linkageId === _ctx.HAPIACA_LINKAGE_ID)
          ? (_openBlock(), _createBlock(_component_HapiacaMailMagazine, {
              key: 0,
              nextRoute: _ctx.routeNames.MAIL_MAGAZINE_CONFIRM
            }, null, 8, ["nextRoute"]))
          : (_ctx.linkageInfo.linkageId === _ctx.SHUEISHA_ONLINE_LINKAGE_ID)
            ? (_openBlock(), _createBlock(_component_ShueishaOnlineMailMagazine, {
                key: 1,
                nextRoute: _ctx.routeNames.MAIL_MAGAZINE_CONFIRM
              }, null, 8, ["nextRoute"]))
            : _createCommentVNode("", true)
      ])
    ])
  ]))
}