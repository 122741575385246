import { SidUserClient } from "@sid/sid_client";
import { BaseInfo, CommonAttrInfo, LinkageUserData, ManageInfo, MemberAgreementInfo, ShippingInfo, SiteInfo, UserBase, UserShueishaOnline } from "@/commons/interfaces";
import { InjectionKey, reactive } from "vue";
import { LinkageUserItem } from "@/modules/linkage/linkageUserItem";
import ShueishaOnlineProfile from "@/components/Linkage/Sites/ShueishaOnlineProfile.vue";
import ShueishaOnlineConfirm from "@/components/Linkage/Sites/ShueishaOnlineConfirm.vue";

// リマコミ＋関連定数定義
/** 連携ID */
export const SHUEISHA_ONLINE_LINKAGE_ID = 10;
/** 連携名称 */
export const SHUEISHA_ONLINE_LINKAGE_NAME = "ShueishaOnline";
/** 連携パス */
export const SHUEISHA_ONLINE_PATH = "shueisha_online";
/** サイト固有情報APIパス */
export const SHUEISHA_ONLINE_SITE_UNIQ_API_PATH = "/shueisha_online/me";
/** デフォルトコールバックURL */
export const SHUEISHA_ONLINE_DEFAULT_CALLBACK_URL = process.env.VUE_APP_SHUEISHA_ONLINE_DEFAULT_CALLBACK_URL;
/** 許容コールバックURL */
export const SHUEISHA_ONLINE_ACCEPT_CALLBACK_URLS = process.env.VUE_APP_SHUEISHA_ONLINE_ACCEPT_CALLBACK_URLS.split(",").map((url: string) => `^${url}(\\?.*)?$`);
/** サービス規約URL */
export const SHUEISHA_ONLINE_SERVICE_TERM_URL = process.env.VUE_APP_SHUEISHA_ONLINE_SERVICE_TERM_URL;
/** メールアドレスドメイン */
export const SHUEISHA_ONLINE_EMAIL_DOMAINS = [
    "online.sid.shueisha.co.jp",
];

/** 基本属性情報入力 */
export const SHUEISHA_ONLINE_PROFILE_COMPONENT = ShueishaOnlineProfile;
/** 基本属性情報確認 */
export const SHUEISHA_ONLINE_CONFIRM_COMPONENT = ShueishaOnlineConfirm;

/**
 * リマコミ＋連携画面用基本属性情報管理クラス
 */
export class ShueishaOnlineUserBase implements LinkageUserData<UserBase> {

    /** インジェクションキー */
    public static injectionKey: InjectionKey<LinkageUserItem<LinkageUserData<UserBase>>> = Symbol("ShueishaOnlineUserBase");

    /** 基本属性情報 */
    public readonly data: UserBase = reactive(new UserBase());
    /**
     * @inheritDoc LinkageUserData
     */
    public initData(): void {
        this.copy(new UserBase());
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public getData(): UserBase {
        return this.data;
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public copy(obj: UserBase): void {
        this.data.identityId = obj.identityId;
        this.data.userName = obj.userName;
        this.data.baseInfo = Object.assign(new BaseInfo(), obj.baseInfo);
        this.data.shippingInfo = obj.shippingInfo?.map(inf => Object.assign(new ShippingInfo(), inf)) || [];
        this.data.siteInfo = obj.siteInfo?.map(inf => Object.assign(new SiteInfo(), inf)) || [];
        this.data.commonAttrInfo = Object.assign(new CommonAttrInfo(), obj.commonAttrInfo);
        this.data.memberAgreeInfo = Object.assign(new MemberAgreementInfo(), obj.memberAgreeInfo);
        this.data.manageInfo = Object.assign(new ManageInfo(), obj.manageInfo);
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public async fetch(sidClient: SidUserClient): Promise<void> {
        const response = await sidClient.getUserAttr()
            .then(res => res.data)
            .catch(res => {
                if (res.response?.status === 404) {
                    return new UserBase();
                } else {
                    throw new Error("Failed to get information.");
                }
            });
        this.copy(response);
    }

    /**
     * @inheritDoc LinkageUserData TODO
     */
    public checkRequired(): boolean {
        // 基本属性情報の有無
        if (!this.data.identityId) return false;
        // 登録者情報
        // if (!this.data.baseInfo.firstName) return false;
        // if (!this.data.baseInfo.lastName) return false;
        // if (!this.data.baseInfo.firstKana) return false;
        // if (!this.data.baseInfo.lastKana) return false;
        // if (!this.data.baseInfo.zipCode) return false;
        // if (!this.data.baseInfo.prefecture) return false;
        // if (!this.data.baseInfo.address) return false;
        // if (!this.data.baseInfo.building) return false;
        if (!this.data.baseInfo.email) return false;
        // if (!this.data.baseInfo.tel) return false;
        // 属性情報
        // if (!this.data.commonAttrInfo.gender) return false;
        // if (!this.data.commonAttrInfo.birthday) return false;
        // if (!this.data.commonAttrInfo.imagePath) return false;
        // if (!this.data.commonAttrInfo.nickName) return false;
        // if (this.data.commonAttrInfo.isMarried === null || this.data.commonAttrInfo.isMarried === undefined) return false;
        // if (this.data.commonAttrInfo.hasChild === null || this.data.commonAttrInfo.hasChild === undefined) return false;
        // if (!this.data.commonAttrInfo.bloodType) return false;
        return true;
    }

    /**
     * @inheritDoc LinkageUserData
     */
    getInjectKey(): InjectionKey<LinkageUserItem<LinkageUserData<UserBase>>> {
        return ShueishaOnlineUserBase.injectionKey;
    }

}

/**
 * リマコミ＋連携画面用サイト固有情報管理クラス
 */
export class ShueishaOnlineSiteUniq implements LinkageUserData<UserShueishaOnline> {

    /** インジェクションキー */
    public static injectionKey: InjectionKey<LinkageUserItem<LinkageUserData<UserShueishaOnline>>> = Symbol("UserShueishaOnline");

    /** リマコミ＋サイト固有情報 */
    public readonly data: UserShueishaOnline = reactive(new UserShueishaOnline());

    /**
     * @inheritDoc LinkageUserData
     */
    public initData(): void {
        this.copy(new UserShueishaOnline());
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public getData(): UserShueishaOnline {
        return this.data;
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public copy(obj: UserShueishaOnline): void {
        this.data.identityId = obj.identityId;
        this.data.userName = obj.userName;
        this.data.isSendMailMagazine = obj.isSendMailMagazine;
        this.data.manageInfo = Object.assign({}, obj.manageInfo);
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public async fetch(sidClient: SidUserClient): Promise<void> {
        const response = await sidClient.requestApi({ method: "GET", path: SHUEISHA_ONLINE_SITE_UNIQ_API_PATH })
            .then(res => res.data)
            .catch(res => {
                if (res.response?.status === 404) {
                    return new UserShueishaOnline();
                } else {
                    throw new Error("Failed to get information.");
                }
            });
        this.copy(response);
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public checkRequired(): boolean {
        // サイト固有情報の有無
        if (this.data.isSendMailMagazine === null || this.data.isSendMailMagazine === undefined) return false;
        return true;
    }

    /**
     * @inheritDoc LinkageUserData
     */
    getInjectKey(): InjectionKey<LinkageUserItem<LinkageUserData<UserShueishaOnline>>> {
        return ShueishaOnlineSiteUniq.injectionKey;
    }
}
