import { NavigationGuardNext, NavigationGuardWithThis, RouteLocationNormalized, RouteLocationNormalizedLoaded, RouteRecordRaw } from "vue-router";
import LinkageLogin from "@/views/Linkage/Auth/LinkageLogin.vue";
import LinkageResetPassword from "@/views/Linkage/Auth/LinkageResetPassword.vue";
import LinkageNewPassword from "@/views/Linkage/Auth/LinkageNewPassword.vue";
import LinkageSignupParent from "@/views/Linkage/Signup/LinkageSignupParent.vue";
import LinkageSignup from "@/views/Linkage/Signup/LinkageSignup.vue";
import LinkageSignupCode from "@/views/Linkage/Signup/LinkageSignupCode.vue";
import LinkageSignupProfile from "@/views/Linkage/Signup/LinkageSignupProfile.vue";
import LinkageSignupConfirm from "@/views/Linkage/Signup/LinkageSignupConfirm.vue";
import LinkageParent from "@/views/Linkage/LinkageParent.vue";
import LinkageSignupComplete from "@/views/Linkage/Signup/LinkageSignupComplete.vue";
import LinkageUserParent from "@/views/Linkage/User/LinkageUserParent.vue";
import LinkageProfile from "@/views/Linkage/User/LinkageProfile.vue";
import LinkageProfileConfirm from "@/views/Linkage/User/LinkageProfileConfirm.vue";
import LinkageProfileComplete from "@/views/Linkage/User/LinkageProfileComplete.vue";
import LinkageChangePassword from "@/views/Linkage/User/LinkageChangePassword.vue";
import LinkageChangePasswordComplete from "@/views/Linkage/User/LinkageChangePasswordComplete.vue";
import LinkageChangePasswordRescueProfile from "@/views/Linkage/User/LinkageChangePasswordRescueProfile.vue";
import LinkageChangePasswordRescueProfileConfirm from "@/views/Linkage/User/LinkageChangePasswordRescueProfileConfirm.vue";
import LinkageChangeEmail from "@/views/Linkage/User/LinkageChangeEmail.vue";
import LinkageChangeEmailConfirm from "@/views/Linkage/User/LinkageChangeEmailConfirm.vue";
import LinkageChangeEmailComplete from "@/views/Linkage/User/LinkageChangeEmailComplete.vue";
import LinkageChangeEmailRescueProfile from "@/views/Linkage/User/LinkageChangeEmailRescueProfile.vue";
import LinkageChangeEmailRescueProfileConfirm from "@/views/Linkage/User/LinkageChangeEmailRescueProfileConfirm.vue";
import LinkageWithdrawal from "@/views/Linkage/User/LinkageWithdrawal.vue";
import LinkageGeneralError from "@/views/Linkage/LinkageGeneralError.vue";
import LinkageSetPassword from "@/views/Linkage/Auth/LinkageSetPassword.vue";
import LinkageIdentification from "@/views/Linkage/Auth/LinkageIdentification.vue";
import LinkageLoginProfileParent from "@/views/Linkage/Auth/LinkageLoginProfileParent.vue";
import LinkageLoginProfile from "@/views/Linkage/Auth/LinkageLoginProfile.vue";
import LinkageLoginProfileConfirm from "@/views/Linkage/Auth/LinkageLoginProfileConfirm.vue";
import { LinkageRouteNames } from "@/commons/linkage/linkageCommon";
import { loginStatus } from "@/modules/loginStatus";
import { sidClient } from "@/plugins/auth";
import { commonRoute } from "@/router/portal";

/**
 * 連携画面用ルート定義作成
 * @param id
 * @param name
 * @param pathName
 * @param siteUniqApiPath
 * @param defaultCallbackUrl
 * @param acceptCallbackUrls
 * @param serviceTermUrl
 * @param emailDomains
 * @param profileComponent
 * @param comfirmComponent
 * @param loginCustomComponent
 */
export function createLinkageRoute(
    id: number,
    name: string,
    pathName: string,
    siteUniqApiPath: string,
    defaultCallbackUrl: string,
    acceptCallbackUrls: Array<string>,
    serviceTermUrl: string,
    emailDomains: Array<string>,
    profileComponent: any,
    comfirmComponent: any,
    options?: {
        loginCustomComponent?: any;
        withDrawCustomComponent?: any;
        profileCompleteCustomComponent?: any;
        linkageSubId?: number;
        loginStateUnused?: boolean;
        emailParamNotRequired?: boolean;
    }
): Array<RouteRecordRaw> {
    // ルート名オブジェクト作成
    const RouteNames = new LinkageRouteNames(id, name);

    const route = [
        {
            path: `/linkage/${pathName}`,
            name: RouteNames.PARENT,
            component: LinkageParent,
            meta: { linkageName: name },
            props: {
                linkageId: id,
                linkageName: name,
                pathName: pathName,
                siteUniqApiPath: siteUniqApiPath,
                siteDefaultCallbackUrl: defaultCallbackUrl,
                acceptCallbackUrls: acceptCallbackUrls,
                serviceTermUrl: serviceTermUrl,
                emailDomains: emailDomains,
                linkageSubId: options?.linkageSubId,
                loginStateUnused: options?.loginStateUnused,
                emailParamNotRequired: options?.emailParamNotRequired,
            },
            children: [
                {
                    path: "login",
                    name: RouteNames.LOGIN,
                    component: LinkageLogin,
                    meta: { title: "ログイン" },
                    props: true,
                    children: options?.loginCustomComponent ? [
                        {
                            path: "",
                            name: `${RouteNames.LOGIN}Child`,
                            component: options.loginCustomComponent,
                        }
                    ] : []
                },
                {
                    path: "identification",
                    name: RouteNames.IDENTIFICATION,
                    component: LinkageIdentification,
                    meta: { title: "本人確認" },
                    props: true
                },
                {
                    path: "new_password",
                    name: RouteNames.NEW_PASSWORD,
                    component: LinkageNewPassword,
                    meta: { title: "新規パスワード設定" },
                    beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
                        if (!from.name) {
                            if (to.query.retUrl)
                                next({ name: to.query.retUrl as string });
                            else
                                next({ name: RouteNames.LOGIN });
                        } else {
                            next();
                        }
                    },
                },
                {
                    path: "reset_password",
                    name: RouteNames.RESET_PASSWORD,
                    component: LinkageResetPassword,
                    meta: { title: "パスワードリセット" },
                },
                {
                    path: "set_password",
                    name: RouteNames.SET_PASSWORD,
                    component: LinkageSetPassword,
                    meta: { title: "パスワード再設定" },
                },
                {
                    path: "",
                    name: RouteNames.LOGIN_PROFILE_PARENT,
                    component: LinkageLoginProfileParent,
                    children: [
                        {
                            path: "profile",
                            name: RouteNames.LOGIN_PROFILE,
                            component: LinkageLoginProfile,
                            meta: { title: "会員情報変更" },
                            props: true,
                            children: [
                                {
                                    path: "",
                                    name: `${RouteNames.LOGIN_PROFILE}Child`,
                                    component: profileComponent,
                                    props: { nextRoute: `${RouteNames.LOGIN_PROFILE_CONFIRM}Child` }
                                }
                            ]
                        },
                        {
                            path: "confirm",
                            name: RouteNames.LOGIN_PROFILE_CONFIRM,
                            component: LinkageLoginProfileConfirm,
                            meta: { title: "会員情報変更(確認)" },
                            beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
                                if (!from.name) {
                                    next({ name: `${RouteNames.LOGIN_PROFILE}Child` });
                                } else {
                                    next();
                                }
                            },
                            children: [
                                {
                                    path: "",
                                    name: `${RouteNames.LOGIN_PROFILE_CONFIRM}Child`,
                                    component: comfirmComponent,
                                    props: { prevRoute: `${RouteNames.LOGIN_PROFILE}Child`, nextRoute: null }
                                }
                            ]
                        },
                    ]
                },
                {
                    path: "signup",
                    name: RouteNames.SIGNUP_PARENT,
                    component: LinkageSignupParent,
                    children: [
                        {
                            path: "identification",
                            name: RouteNames.SIGNUP_IDENTIFICATION,
                            component: LinkageIdentification,
                            meta: { title: "本人確認" },
                            props: true
                        },
                        {
                            path: "new_password",
                            name: RouteNames.SIGNUP_NEW_PASSWORD,
                            component: LinkageNewPassword,
                            meta: { title: "新規パスワード設定" },
                            beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
                                if (!from.name) {
                                    if (to.query.retUrl)
                                        next({ name: to.query.retUrl as string });
                                    else
                                        next({ name: RouteNames.SIGNUP_IDENTIFICATION, query: to.query });
                                } else {
                                    next();
                                }
                            },
                        },
                        {
                            path: "reset_password",
                            name: RouteNames.SIGNUP_RESET_PASSWORD,
                            component: LinkageResetPassword,
                            meta: { title: "パスワードリセット" },
                        },
                        {
                            path: "set_password",
                            name: RouteNames.SIGNUP_SET_PASSWORD,
                            component: LinkageSetPassword,
                            meta: { title: "パスワード再設定" },
                        },
                        {
                            path: "",
                            name: RouteNames.SIGNUP,
                            component: LinkageSignup,
                            meta: { title: "新規会員登録" },
                        },
                        {
                            path: "code",
                            name: RouteNames.SIGNUP_CODE,
                            component: LinkageSignupCode,
                            meta: { title: "新規会員登録(確認コード入力)" },
                        },
                        {
                            path: "code/:username",
                            name: RouteNames.SIGNUP_CODE_FROM_LINK,
                            component: LinkageSignupCode,
                            meta: { title: "新規会員登録(確認コード入力)" },
                        },
                        {
                            path: "profile",
                            name: RouteNames.SIGNUP_PROFILE,
                            component: LinkageSignupProfile,
                            meta: { title: "新規会員登録(会員情報入力)" },
                            props: true,
                            children: [
                                {
                                    path: "",
                                    name: `${RouteNames.SIGNUP_PROFILE}Child`,
                                    component: profileComponent,
                                    props: { nextRoute: `${RouteNames.SIGNUP_CONFIRM}Child` }
                                }
                            ]
                        },
                        {
                            path: "confirm",
                            name: RouteNames.SIGNUP_CONFIRM,
                            component: LinkageSignupConfirm,
                            meta: { title: "新規会員登録(会員情報確認)" },
                            beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
                                if (!from.name) {
                                    next({ name: `${RouteNames.SIGNUP_PROFILE}Child` });
                                } else {
                                    next();
                                }
                            },
                            children: [
                                {
                                    path: "",
                                    name: `${RouteNames.SIGNUP_CONFIRM}Child`,
                                    component: comfirmComponent,
                                    props: { prevRoute: `${RouteNames.SIGNUP_PROFILE}Child`, nextRoute: RouteNames.SIGNUP_COMPLETE }
                                }
                            ]
                        },
                        {
                            path: "complete",
                            name: RouteNames.SIGNUP_COMPLETE,
                            component: LinkageSignupComplete,
                            meta: { title: "新規会員登録(完了)" },
                        },
                    ]
                },
                {
                    path: "user",
                    name: RouteNames.USER_PARENT,
                    component: LinkageUserParent,
                    meta: { isMemberOnly: true },
                    redirect: { name: RouteNames.PROFILE },
                    children: [
                        {
                            path: "profile",
                            name: RouteNames.PROFILE,
                            component: LinkageProfile,
                            meta: { title: "会員情報変更" },
                            props: true,
                            children: [
                                {
                                    path: "",
                                    name: `${RouteNames.PROFILE}Child`,
                                    component: profileComponent,
                                    props: { nextRoute: `${RouteNames.PROFILE_CONFIRM}Child` }
                                }
                            ]
                        },
                        {
                            path: "profile_confirm",
                            name: RouteNames.PROFILE_CONFIRM,
                            component: LinkageProfileConfirm,
                            meta: { title: "会員情報変更(確認)" },
                            props: true,
                            beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
                                if (!from.name) {
                                    next({ name: `${RouteNames.PROFILE}Child` });
                                } else {
                                    next();
                                }
                            },
                            children: [
                                {
                                    path: "",
                                    name: `${RouteNames.PROFILE_CONFIRM}Child`,
                                    component: comfirmComponent,
                                    props: { prevRoute: `${RouteNames.PROFILE}Child`, nextRoute: RouteNames.PROFILE_COMPLETE }
                                }
                            ]
                        },
                        {
                            path: "profile_complete",
                            name: RouteNames.PROFILE_COMPLETE,
                            component: LinkageProfileComplete,
                            meta: { title: "会員情報変更(完了)" },
                            children: options?.profileCompleteCustomComponent ? [
                                {
                                    path: "",
                                    name: `${RouteNames.PROFILE_COMPLETE}Child`,
                                    component: options.profileCompleteCustomComponent,
                                }
                            ] : []

                        },
                        {
                            path: "change_password",
                            name: RouteNames.CHANGE_PASSWORD,
                            component: LinkageChangePassword,
                            meta: { title: "パスワード変更" },
                        },
                        {
                            path: "change_password_complete",
                            name: RouteNames.CHANGE_PASSWORD_COMPLETE,
                            component: LinkageChangePasswordComplete,
                            meta: { title: "パスワード変更(完了)" },
                        },
                        {
                            path: "change_password/rescue_profile",
                            name: RouteNames.CHANGE_PASSWORD_RESCUE_PROFILE,
                            component: LinkageChangePasswordRescueProfile,
                            meta: { title: "会員情報変更" },
                            props: true,
                            children: [
                                {
                                    path: "",
                                    name: `${RouteNames.CHANGE_PASSWORD_RESCUE_PROFILE}Child`,
                                    component: profileComponent,
                                    props: { nextRoute: `${RouteNames.CHANGE_PASSWORD_RESCUE_PROFILE_CONFIRM}Child` }
                                }
                            ]
                        },
                        {
                            path: "change_password/rescue_profile_confirm",
                            name: RouteNames.CHANGE_PASSWORD_RESCUE_PROFILE_CONFIRM,
                            component: LinkageChangePasswordRescueProfileConfirm,
                            meta: { title: "会員情報変更(確認)" },
                            beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
                                if (!from.name) {
                                    next({ name: `${RouteNames.CHANGE_PASSWORD_RESCUE_PROFILE}Child` });
                                } else {
                                    next();
                                }
                            },
                            children: [
                                {
                                    path: "",
                                    name: `${RouteNames.CHANGE_PASSWORD_RESCUE_PROFILE_CONFIRM}Child`,
                                    component: comfirmComponent,
                                    props: { prevRoute: `${RouteNames.CHANGE_PASSWORD_RESCUE_PROFILE}Child`, nextRoute: RouteNames.CHANGE_PASSWORD }
                                }
                            ]
                        },
                        {
                            path: "change_email",
                            name: RouteNames.CHANGE_EMAIL,
                            component: LinkageChangeEmail,
                            meta: { title: "メールアドレス変更" },
                        },
                        {
                            path: "change_email_confirm",
                            name: RouteNames.CHANGE_EMAIL_CONFIRM,
                            component: LinkageChangeEmailConfirm,
                            meta: { title: "メールアドレス変更(確認)" },
                        },
                        {
                            path: "change_email_complete",
                            name: RouteNames.CHANGE_EMAIL_COMPLETE,
                            component: LinkageChangeEmailComplete,
                            meta: { title: "メールアドレス変更(完了)" },
                        },
                        {
                            path: "change_email/rescue_profile",
                            name: RouteNames.CHANGE_EMAIL_RESCUE_PROFILE,
                            component: LinkageChangeEmailRescueProfile,
                            meta: { title: "会員情報変更" },
                            props: true,
                            children: [
                                {
                                    path: "",
                                    name: `${RouteNames.CHANGE_EMAIL_RESCUE_PROFILE}Child`,
                                    component: profileComponent,
                                    props: { nextRoute: `${RouteNames.CHANGE_EMAIL_RESCUE_PROFILE_CONFIRM}Child` }
                                }
                            ]
                        },
                        {
                            path: "change_email/rescue_profile_confirm",
                            name: RouteNames.CHANGE_EMAIL_RESCUE_PROFILE_CONFIRM,
                            component: LinkageChangeEmailRescueProfileConfirm,
                            meta: { title: "会員情報変更(確認)" },
                            beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
                                if (!from.name) {
                                    next({ name: `${RouteNames.CHANGE_EMAIL_RESCUE_PROFILE}Child` });
                                } else {
                                    next();
                                }
                            },
                            children: [
                                {
                                    path: "",
                                    name: `${RouteNames.CHANGE_EMAIL_RESCUE_PROFILE_CONFIRM}Child`,
                                    component: comfirmComponent,
                                    props: { prevRoute: `${RouteNames.CHANGE_EMAIL_RESCUE_PROFILE}Child`, nextRoute: RouteNames.CHANGE_EMAIL }
                                }
                            ]
                        },
                        {
                            path: "withdraw",
                            name: RouteNames.WITHDRAWAL,
                            component: LinkageWithdrawal,
                            meta: { title: "退会" },
                            children: options?.withDrawCustomComponent ? [
                                {
                                    path: "",
                                    name: `${RouteNames.WITHDRAWAL}Child`,
                                    component: options?.withDrawCustomComponent,
                                }
                            ] : []
                        },
                    ]
                },
                {
                    path: "error",
                    name: RouteNames.GENERAL_ERROR,
                    component: LinkageGeneralError,
                    meta: { title: "エラー" },
                    props: true
                },
            ]
        },
        // 共通のルート定義（ポータルとパスを合わせることで整合させる）
        ...commonRoute,
        {
            path: "/:catchAll(.*)",
            redirect: () => {
                return { name: RouteNames.GENERAL_ERROR };
            }
        },
    ];

    return route;
}

// 遷移前イベントハンドラ
export const linkageBeforeEach: NavigationGuardWithThis<undefined> = async (to, from, next) => {
    // 認証状態を取得して保持
    loginStatus.value = await sidClient.isAuthenticated();
    // ログイン必須のページの場合
    if (to.matched.some(record => record.meta.isMemberOnly)) {
        // ログイン状態判定
        if (loginStatus.value) {
            // ログイン済みの場合は対象の画面に遷移する
            next();
        } else {
            // 連携画面の場合
            const toQuery = to.query;
            toQuery.retUrl = to.name as string;
            next({ name: `${to.meta.linkageName}Identification`, query: toQuery });
        }
    } else {
        // ログイン必須ではないページはそのまま対象ページに遷移する
        next();
    }
};

// 連携画面用スクロール設定
export const linkageScrollBehavior = async (to: RouteLocationNormalized, from: RouteLocationNormalizedLoaded, savedPosition: any) => {
    if (savedPosition) {
        return savedPosition;
    } else if (to.hash) {
        return { el: to.hash, behavior: "smooth" };
    } else {
        return { left: 0, top: 0 };
    }
};
