import { HAPIACA_LINKAGE_ID, HapiacaSiteUniq, HapiacaUserBase } from "@/commons/linkage/sites/hapiaca";
import { LinkageUserData, UserBase } from "@/commons/interfaces";
import { LinkageUserItem } from "@/modules/linkage/linkageUserItem";
import { LEE_LINKAGE_ID, LeeSiteUniq, LeeUserBase } from "@/commons/linkage/sites/lee";
import { MANGASAI_LINKAGE_ID, MangasaiUserBase } from "@/commons/linkage/sites/mangasai";
import { NotExistSiteUniq } from "@/commons/linkage/sites/common";
import { UOMO_LINKAGE_ID, UomoSiteUniq, UomoUserBase } from "./sites/uomo";
import { RIMACOMI_LINKAGE_ID, RimacomiSiteUniq, RimacomiUserBase } from "./sites/rimacomi";
import { MENS_MAGAZINE_LINKAGE_ID, MensMagazineSiteUniq, MensMagazineUserBase } from "@/commons/linkage/sites/mensmagazine";
import { SHUEISHA_ONLINE_LINKAGE_ID, ShueishaOnlineSiteUniq, ShueishaOnlineUserBase } from "@/commons/linkage/sites/shueisha_online";

/**
 * 連携画面用基本属性情報クラス型の取得
 * @param linkageId
 */
export function getLinkageUserBaseType(linkageId: number) {
    switch (linkageId) {
        case HAPIACA_LINKAGE_ID:
            return HapiacaUserBase;
        case LEE_LINKAGE_ID:
            return LeeUserBase;
        case MANGASAI_LINKAGE_ID:
            return MangasaiUserBase;
        case UOMO_LINKAGE_ID:
            return UomoUserBase;
        case RIMACOMI_LINKAGE_ID:
            return RimacomiUserBase;
        case MENS_MAGAZINE_LINKAGE_ID:
            return MensMagazineUserBase;
        case SHUEISHA_ONLINE_LINKAGE_ID:
            return ShueishaOnlineUserBase;
        default:
            throw new Error("not implemented");
    }
}

/**
 * 連携画面用サイト固有情報クラス型の取得
 * @param linkageId
 */
export function getLinkageSiteUniqType(linkageId: number) {
    switch (linkageId) {
        case HAPIACA_LINKAGE_ID:
            return HapiacaSiteUniq;
        case LEE_LINKAGE_ID:
            return LeeSiteUniq;
        case MANGASAI_LINKAGE_ID:
            return NotExistSiteUniq;
        case UOMO_LINKAGE_ID:
            return UomoSiteUniq;
        case RIMACOMI_LINKAGE_ID:
            return RimacomiSiteUniq;
        case MENS_MAGAZINE_LINKAGE_ID:
            return MensMagazineSiteUniq;
        case SHUEISHA_ONLINE_LINKAGE_ID:
            return ShueishaOnlineSiteUniq;
        default:
            throw new Error("not implemented");
    }
}

/**
 * 連携ユーザ情報（基本属性）をinject()する為のキー取得
 * @param linkageId
 */
export function getUserBaseInjectKey(linkageId: number) {
    const type = getLinkageUserBaseType(linkageId);
    return type.injectionKey;
}

/**
 * 連携ユーザ情報（サイト固有）をinject()する為のキー取得
 * @param linkageId
 */
export function getSiteUniqInjectKey(linkageId: number) {
    const type = getLinkageSiteUniqType(linkageId);
    return type.injectionKey;
}

/**
 * 連携ユーザ情報（基本属性）をinject()する
 * @param linkageId
 */
export function injectUserBaseItem(linkageId: number) {
    return LinkageUserItem.inject(getUserBaseInjectKey(linkageId)) as LinkageUserItem<LinkageUserData<UserBase>>;
}

/**
 * 連携ユーザ情報（サイト固有）をinject()する
 * @param linkageId
 */
export function injectSiteUniqItem(linkageId: number) {
    return LinkageUserItem.inject(getSiteUniqInjectKey(linkageId));
}

/**
 * 連携画面のルート名称クラス
 */
export class LinkageRouteNames {
    public readonly PARENT: string;
    public readonly LOGIN: string;
    public readonly IDENTIFICATION: string;
    public readonly NEW_PASSWORD: string;
    public readonly RESET_PASSWORD: string;
    public readonly SET_PASSWORD: string;
    public readonly LOGIN_PROFILE_PARENT: string;
    public readonly LOGIN_PROFILE: string;
    public readonly LOGIN_PROFILE_CONFIRM: string;
    public readonly SIGNUP_PARENT: string;
    public readonly SIGNUP_IDENTIFICATION: string;
    public readonly SIGNUP_NEW_PASSWORD: string;
    public readonly SIGNUP_RESET_PASSWORD: string;
    public readonly SIGNUP_SET_PASSWORD: string;
    public readonly SIGNUP: string;
    public readonly SIGNUP_CODE: string;
    public readonly SIGNUP_CODE_FROM_LINK: string;
    public readonly SIGNUP_PROFILE: string;
    public readonly SIGNUP_CONFIRM: string;
    public readonly SIGNUP_COMPLETE: string;
    public readonly USER_PARENT: string;
    public readonly PROFILE: string;
    public readonly PROFILE_CONFIRM: string;
    public readonly PROFILE_COMPLETE: string;
    public readonly CHANGE_PASSWORD: string;
    public readonly CHANGE_PASSWORD_COMPLETE: string;
    public readonly CHANGE_PASSWORD_RESCUE_PROFILE: string;
    public readonly CHANGE_PASSWORD_RESCUE_PROFILE_CONFIRM: string;
    public readonly CHANGE_EMAIL: string;
    public readonly CHANGE_EMAIL_CONFIRM: string;
    public readonly CHANGE_EMAIL_COMPLETE: string;
    public readonly CHANGE_EMAIL_RESCUE_PROFILE: string;
    public readonly CHANGE_EMAIL_RESCUE_PROFILE_CONFIRM: string;
    public readonly MAIL_MAGAZINE: string;
    public readonly MAIL_MAGAZINE_CONFIRM: string;
    public readonly MAIL_MAGAZINE_COMPLETE: string;
    public readonly MAIL_MAGAZINE_RESCUE_PROFILE: string;
    public readonly MAIL_MAGAZINE_RESCUE_PROFILE_CONFIRM: string;
    public readonly WITHDRAWAL: string;
    public readonly GENERAL_ERROR: string;

    /**
     * コンストラクタ
     * @param linkageId 連携ID
     * @param linkageName 連携名称
     */
    constructor(linkageId: number, linkageName: string) {
        this.PARENT = `${linkageName}Parent`;
        this.LOGIN = `${linkageName}Login`;
        this.IDENTIFICATION = `${linkageName}Identification`;
        this.NEW_PASSWORD = `${linkageName}NewPassword`;
        this.RESET_PASSWORD = `${linkageName}ResetPassword`;
        this.SET_PASSWORD = `${linkageName}SetPassword`;
        this.LOGIN_PROFILE_PARENT = `${linkageName}LoginProfileParent`;
        this.LOGIN_PROFILE = `${linkageName}LoginProfile`;
        this.LOGIN_PROFILE_CONFIRM = `${linkageName}LoginProfileConfirm`;
        this.SIGNUP_PARENT = `${linkageName}SignupParent`;
        this.SIGNUP_IDENTIFICATION = `${linkageName}SignupIdentification`;
        this.SIGNUP_NEW_PASSWORD = `${linkageName}SignupNewPassword`;
        this.SIGNUP_RESET_PASSWORD = `${linkageName}SignupResetPassword`;
        this.SIGNUP_SET_PASSWORD = `${linkageName}SignupSetPassword`;
        this.SIGNUP = `${linkageName}Signup`;
        this.SIGNUP_CODE = `${linkageName}SignupCode`;
        this.SIGNUP_CODE_FROM_LINK = `${linkageName}SignupCodeFromLink`;
        this.SIGNUP_PROFILE = `${linkageName}SignupProfile`;
        this.SIGNUP_CONFIRM = `${linkageName}SignupConfirm`;
        this.SIGNUP_COMPLETE = `${linkageName}SignupComplete`;
        this.USER_PARENT = `${linkageName}UserParent`;
        this.PROFILE = `${linkageName}Profile`;
        this.PROFILE_CONFIRM = `${linkageName}ProfileConfirm`;
        this.PROFILE_COMPLETE = `${linkageName}ProfileComplete`;
        this.CHANGE_PASSWORD = `${linkageName}ChangePassword`;
        this.CHANGE_PASSWORD_COMPLETE = `${linkageName}ChangePasswordComplete`;
        this.CHANGE_PASSWORD_RESCUE_PROFILE = `${linkageName}ChangePasswordRescueProfile`;
        this.CHANGE_PASSWORD_RESCUE_PROFILE_CONFIRM = `${linkageName}ChangePasswordRescueProfileConfirm`;
        this.CHANGE_EMAIL = `${linkageName}ChangeEmail`;
        this.CHANGE_EMAIL_CONFIRM = `${linkageName}ChangeEmailConfirm`;
        this.CHANGE_EMAIL_COMPLETE = `${linkageName}ChangeEmailComplete`;
        this.CHANGE_EMAIL_RESCUE_PROFILE = `${linkageName}ChangeEmailRescueProfile`;
        this.CHANGE_EMAIL_RESCUE_PROFILE_CONFIRM = `${linkageName}ChangeEmailRescueProfileConfirm`;
        this.MAIL_MAGAZINE = `${linkageName}MailMagazine`;
        this.MAIL_MAGAZINE_CONFIRM = `${linkageName}MailMagazineConfirm`;
        this.MAIL_MAGAZINE_COMPLETE = `${linkageName}MailMagazineComplete`;
        this.MAIL_MAGAZINE_RESCUE_PROFILE = `${linkageName}MailMagazineRescueProfile`;
        this.MAIL_MAGAZINE_RESCUE_PROFILE_CONFIRM = `${linkageName}MailMagazineRescueProfileConfirm`;
        this.WITHDRAWAL = `${linkageName}Withdrawal`;
        this.GENERAL_ERROR = `${linkageName}GeneralError`;
    }
}
